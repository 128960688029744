<template>
  <div class="mt-30 text-left flex-column">
    <div
        class="flex-1"
    >
      <div class="under-line-bbb pb-16 flex-row">
        <h3 class="flex-1 size-px-20">MAFIA CARD</h3>
      </div>
      <div class="pa-20 under-line-bbb">
        <div class="container justify-center">
          <div class="flex-row-wrap">
            <div
                class="nft_item"
                v-for="(item, index) in cartl_nft_purchase_list"
                :key="'item_' + index"
                :style="'background-image: url(' + item.cartl_nft_img_url + ');'"
            >
              <div class="tit">
                <img :src="item.cartl_nft_catgy_img_url" alt="MAFIA CARD">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pa-20">
      <div class="flex-row justify-space-between mt-20">
        <div class="size-px-16 font-weight-700">BUY
          <span style="color: #DC505C">{{ purchase_info.last_payment_amount_quantity }}{{ purchase_info.payment_money_name }}</span>
        </div>
        <div class="color-gray">
          NFT 컬랙션에서 구입하신 MAFIA CARD를 확인하실 수 있습니다.
        </div>
      </div>
      <div class="flex-row justify-space-between mt-20">
        <button class="btn_outline_blue btn_l" @click="$router.replace({ name: 'nft'})">NFT 목록</button>
        <button
            class="btn_fill_blue btn_l"
            @click="goBack"
        >확인
        </button>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "PurchaseNft"
  , props: ['user']
  , data: function () {
    return {
      program: {
        name: 'Launchpad'
        , title: 'Launchpad'
        , type: 'Launchpad'
      }
      , purchase_info: []
      , cartl_nft_purchase_list: []
    }
  }
  , methods: {
    goBack: function () {
      this.$router.replace({
        name: 'launchpad',
        params: {
          purchase_item: this.$route.params.nft_info,
        }
      })
    }
  }

  , created() {
    this.$emit('onLoad', this.program)
    console.log(this.$route.params.items, 'nft params')
    console.log(this.user, 'nft params')
    this.purchase_info = this.$route.params.items
    this.cartl_nft_purchase_list = this.$route.params.items.cartl_nft_purchase_list
  }
}
</script>

<style scoped>
.btn_l {
  display: inline-block;
  height: 50px;
  min-width: 90px;
  padding: 13px 30px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.btn_outline_blue {
  border: 1px solid var(--mafia-Blue);
  color: var(--mafia-Blue);
  background-color: #fff;
}

.nft_item {
  width: 260px;
  height: 260px;
  background-size: cover;
  background-position: center center;
  border-radius: 15px;
  padding: 20px;
  margin: 20px 10px;
}
</style>
